<template>
  <section class="page-container">
    <lazy-dom>
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
      >
        <el-row
          :gutter="40"
          justify="space-around">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="18"
            :xl="19"
            class="page-content-and-up"
          >
            <div class="flex flex-justify">
              <div
                class="header-title-back flex pointer"
                @click="() => $router.back()"
              >
                <span>
                  Ticket
                </span>
                <img
                  class="title-icon"
                  src="@/assets/images/svg/forward.svg">
                <span class="title-no">
                  {{ticket_no}}
                </span>
              </div>
              <div v-if="draft">
                <v-button
                  class="button-delete"
                  @click="handlerDelete">
                  <font-awesome-icon
                    icon="trash"
                    class="margin-right-five"></font-awesome-icon>
                  <span>Delete</span></v-button>
              </div>
            </div>
            <employees-form
              ref="motorForm"
              @handlerCompanyId="handlerCompanyId"
              @handlerClassId="handlerClassId"
              @getFormData="getSubFormData"></employees-form>
          </el-col>
          <el-col
            class="padding-none-sm-and-down  page-content-auto"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="5"
            :xl="5">
            <div class="page-content-fixed">
              <discount-detail
                ref="discount-detail"
                :isNext="false"
                :isSave="is_save"
                :insurer="insurer"
                :form="formData.premium"
                @next="hanlderNext"
                @draft="handlerDraft"
                @getFormData="getSubFormData"
              ></discount-detail>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </lazy-dom>
  </section>
</template>

<script>
import employeesForm from '@/views/quotation/employees/employeesForm'
import discountDetail from '@/views/components/assginSide/index'
import {saveQuotationEmployee} from '@/api/quotation'
import {deleteOrder,getOrderDetail} from '@/api/order'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import { mapState, mapActions } from 'vuex'
import MixinOrder from '@/mixins/order'
export default {
  name: 'employeeInsurance',
  components: {
    employeesForm,
    discountDetail,
  },
  mixins: [MixinRules,MixinOrder],
  data() {
    return {
      ncdShow:0,
      order_no:'',
      carTypeName: 'Private Car',
      clientType: 1,
      isNext: false,
      cover_type: 1,
      insurer:0,
      policyOneDriver:false,
      subForm: {
        'motorForm':false,
        'discount-detail':false,
      },
      formData: {
        draft:false,
        product_id: 14,//r

        coverage: {
          cover_type: 1, //1 third party 2 comprehensive
          hire_purchase_owner: 1, //1 yes,no-2
          hire_purchase_owner_id: '',
          estimated_vehicle_value: 0,
          policy_send_to_hire_purchase_owner_date: '',
        },

        remark: '',
      },
      is_draft:false
    }
  },
  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    is_save(){
      if(this.quoteInformationForm)
      {
        let {is_save=false} = this.quoteInformationForm
        return is_save
      }return  false
    },
    draft(){
      return this.$route.query.isDraft=='true'
    }
  },
  watch:{
    quoteInformationForm:{
      deep:true,
      immediate:true,
      handler(val){
        let order_no = this.$route.query.order_no||null
        if(!val&&order_no!=null){
          this.network().getOrderDetail({order_no})
        }

      }
    }
  },
  created() {
    this.handlerInit()
  },
  methods: {
    ...mapActions('quotation', ['setQuoteInformationForm','setProductID']),
    handlerInit(){
      let {order_no='',class_id} = this.quoteInformationForm||{}
      this.formData =this.quoteInformationForm||{}
      this.order_no = order_no
    },
    handlerCompanyId(val){
      this.insurer =val
    },
    handlerClassId(val){
      this.carTypeName = val
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    handlerBack() {
      this.$router.back()
    },
    submitData(data,draft){
      this.formData = Object.assign(this.formData, data)
    },
    handlerValidate() {
      // this.$refs.motorForm.handlerValidate()
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }

      if(this.is_draft)
      {

        this.network().draftQuotation()

      }else{
        this.network().saveQuotation()
      }

    },
    hanlderNext(order_no) {
      this.is_draft = false
      this.handlerValidate()
    },
    handlerDraft(){
      this.is_draft =true
      this.handlerValidate()
    },
    // handlerDelete(){
    //   this.network().deleteOrder()
    // },
    network() {
      return {
        saveQuotation: async () => {
          let { data } = await saveQuotationEmployee(this.formData)
          let { order_no } =data
          this.isNext = true
          if(this.is_save){
            this.$router.back()
          }else{
            this.$router.push({
              name: 'info',
              query:{
                order_no,
                isEndorsement:false}
            })
          }
        },
        //草稿单
        draftQuotation: async () => {
          this.is_draft =true
          let params ={
            is_draft:1,
            ...this.formData,
            order_no:this.order_no||''
          }
          let { data } = await saveQuotationEmployee(params)
          let { order_no } =data
          this.order_no = order_no
          this.$ConfirmBox({
            title: '',
            message: 'Save Draft',
            confirmBtn:'OK',
            isCancel:false
          }).then(() => {
          })
        },
        deleteOrder: async () => {
          let params ={
            order_no:this.order_no
          }
          let { data } = await deleteOrder(params)
          this.setQuoteInformationForm(null)
        },
        getOrderDetail: async ({order_no}) => {
          let { data } = await getOrderDetail({order_no})
          let params ={
            order_no,
            ...data.data,
          }
          this.setProductID(params.product_id)
          this.setQuoteInformationForm(params)
          this.handlerInit()
        },
      }
    }
  }
}
</script>
